import { Component } from 'vue-property-decorator'
import { CommonBaseMixin } from '../../components/mixins/base'
import ErrorPage from './ErrorPage.vue'

@Component({
  // @ts-ignore
  preFetch ({ ssrContext }) {
    if (process.env.SERVER) {
      ssrContext?.res?.status(403)
    }
  },
  components: {
    ErrorPage
  }
})
export default class HttpError403 extends CommonBaseMixin {
}
